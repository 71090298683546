export const stations = [
// Possible future idea: could put this in the database in the future
// Notes: 
//   * the first image listed for each stations will be used for the home page slideshow
//   * description, data_availability, and instrumentation are the only fields where you can put in html... but be careful.

// ************************************************************************
// ACTIVE SATATIONS (put inactive station at the bottom of the file)
// ************************************************************************
{
    "directory": "cape-matatula",
    "name": "Cape Matatula",
    "country": "American Samoa",
    "active": true,
    "square_images": [
        require('@/assets/img/stations/cape-matatula/PXL_20230212_213710325.jpg'), // revist this one
        require('@/assets/img/stations/cape-matatula/PXL_20230209_204659967_cropped.jpg'),
        ],
    "tall_images": [
        // require('@/assets/img/stations/cape-matatula/IMG_20180224_150519.jpg'),
        // require('@/assets/img/stations/cape-matatula/IMG_20180223_110010.jpg'),
        // require('@/assets/img/stations/cape-matatula/New BlueSky tower rotated SMO.jpg'),
        ],
    "wide_images": [
        // require('@/assets/img/stations/cape-matatula/PXL_20230209_204659967.jpg'),
        // require('@/assets/img/stations/cape-matatula/PXL_20230212_214033171.PANO.jpg'),
        // require('@/assets/img/stations/cape-matatula/PXL_20230323_231432112.MP.jpg'),
        ],
    "blurb": "The American Samoa Observatory is located on the northeastern tip of Tutuila island, American Samoa, on a ridge overlooking the South Pacific Ocean. The observatory was established in 1974 on a 26.7 acre site as one of the NOAA/ESRL GMD Baseline Observatories.",
    "description": 'The ALE/GAGE/AGAGE started measuring atmospheric CFC-11, CFC-12, CH<sub>3</sub>CCl<sub>3</sub>, CCl<sub>4</sub>, N<sub>2</sub>O in 1978, CFC-113 and CH<sub>4</sub> in late 1985/early 1986, and CHCl<sub>3</sub> in 1996 by using a GC-multidetector (<a href="../instruments/gas-chromatography-multidetector-gc-md">GC-MD</a>) system. A new <a href="../instruments/medusa-gas-chromatography-mass-spectrometry-medusa-gc-ms">Medusa GC-MS</a> instrument was installed in May 2006. Both instruments are housed in the NOAA "Hudson" laboratory building adjacent to a 24-meter sampling and cellular telephone tower on the crest of edge. This station is especially important because it is the only in situ field measurement site where both the AGAGE and GMD networks overlap. <br/><br/> \
                    <a href="https://www.esrl.noaa.gov/gmd/">NOAA\'s Global Monitoring Division</a> also measures a range of ozone depleting substances and greenhouse gases at the American Samoa Observatory.',
    "designation": "SMO",
    "data_availability": '',
    "instrumentation": [
        ],
    "latitude": "14.2474ºS",
    "longitude": "170.5644ºW",
    "elevation": "42 meters above sea level",
    "air_intake": "35 meters above ground level",
    "pi": "Prof Ray Weiss (rfweiss@ucsd.edu); Dr. Jens Mühle (jmuhle@ucsd.edu)",
    "manager": "NOAA SMO Station Chief/NOAA Corps Officer-in-Charge",
    "team": "Jooil Kim, Chris Harth, Roland Schmidt, Jen Gonzalez",
    "email": "smo.staff@noaa.gov",
    "address_name": "NOAA Samoa Observatory",
    "address_street": "P.O. Box 2568",
    "address_last_line": "Pago Pago, Cape Matatula, 96799, American Samoa",
    "related_links": [
        "https://gml.noaa.gov/obop/smo",
        ],
    "funding": "Operation of the American Samoa observatory (SMO) is funded by the National Oceanic and Atmospheric Administration (NOAA) in the USA. AGAGE operations at SMO are funded by the National Aeronautics and Space Administration (NASA) in the USA.",
},
{
    "directory": "kennaookcape-grim",
    "name": "Kennaook/Cape Grim",
    "country": "Australia",
    "active": true,
    "square_images": [
        require('@/assets/img/stations/kennaookcape-grim/Cape_Grim_Aerial_Photos_2008_P1020727.jpg'),  // crystal todo revisit
        ],
    "tall_images": [
        ],
    "wide_images": [
        // require('@/assets/img/stations/kennaookcape-grim/Cape_Grim_PBK_2005_IMG_5616.jpg'),
        ],
    "blurb": "The Cape Grim Baseline Air Pollution Station (CGBAPS) was established by the Australian Government to monitor and study global atmospheric composition.",
    "description": "The Cape Grim Baseline Atmospheric Pollution Station (CGBAPS) commenced operation in early 1976, fulfilling an Australian government commitment to participate in the WMO (World Meteorological Organization) recommended network of global background atmospheric stations, focussed on observing the long-term drivers of climate change and ozone depletion, in particular carbon dioxide (CO<sub>2</sub>), chlorofluorocarbons (CFCs), surface ozone (O<sub>3</sub>) and aerosols. Cape Grim was chosen as a site representative of the mid-latitudes of the Southern Hemisphere and complimented NOAA's Southern Hemisphere sites at <a href=\"../stations/cape-matatula\">Cape Matatula</a>, American Samoa (tropical Pacific) and at the South Pole. A brief history of the Cape Grim Station is given in <a href=\"../static/team/kennaookcape-grim/bl0506_Fraser_History.pdf\">Fraser (2007)</a>. <br/><br/>\
                    At Cape Grim, the ALE/GAGE/AGAGE programs started measuring atmospheric CFC-11, CFC-12, CH<sub>3</sub>CCl<sub>3</sub>, CCl<sub>4</sub>, N<sub>2</sub>O in 1978; CFC-113 in 1982; CH<sub>4</sub> in 1986; and CHCl<sub>3</sub>, CO and H<sub>2</sub> all in 1993 by using a high-precision GC-multidetector (<a href=\"../instruments/gas-chromatography-multidetector-gc-md\">GC-MD</a>) system. The first gas chromatograph-mass spectrometer with adsorption-desorption system (<a href=\"../instruments/gas-chromatography-mass-spectrometry-ads-gc-ms\">ADS GC-MS</a>), was installed at Cape Grim in November 1997. This ADS GC-MS instrument was later retired in December 2004 and replaced by a new state of art <a href=\"../instruments/medusa-gas-chromatography-mass-spectrometry-medusa-gc-ms\">Medusa GC-MS</a> system that was installed and started operation in January 2004. <br/><br/>\
                    Air masses arriving at Cape Grim typically have long trajectories over the Southern Ocean (~40%) or have passed over southern continental Australia or Tasmania (60%). Cape Grim has proved invaluable in assessing the role of the Southern Ocean as a significant sink for atmospheric CO<sub>2</sub> and southern continental Australia as a source of methane (CH<sub>4</sub>), nitrous oxide (N<sub>2</sub>O) and synthetic greenhouse gases (SGGs) - in particular hydrofluorocarbons (HFCs) and CFCs. Cape Grim data have been used extensively in all international assessments of climate change and ozone depletion and in many inverse studies deriving global and regional emissions of CO<sub>2</sub>, CH<sub>4</sub>, N<sub>2</sub>O and SGGs. <br/><br/>\
                    Cape Grim is operated by the Australian government Bureau of Meteorology (BoM) and the extensive atmospheric science research program is overseen by CSIRO and BoM, involving other Australian government agencies (e.g. ANSTO - Australian Nuclear Science Technology Organisation) and universities (e.g. U. Wollongong) and international collaborations with AGAGE, USA laboratories (GIT, MIT, NOAA, SIO, UC Berkeley), European laboratories (Empa, LSCE, NILU, MPI, U. Bristol, U. East Anglia, U. Heidelberg) and Asian laboratories (CMA, NIES, SNU, MMS). <br/><br/>\
                    Fraser, P., A brief history of the Cape Grim Baseline Air Pollution Station, in: Baseline Atmospheric Program Australia 2005-2006, J. Cainey, N. Derek & P. Krummel (eds.), Australian Bureau of Meteorology and CSIRO Marine and Atmospheric Research, Melbourne, Australia, 1-6, 2007. [<a href=\"../static/team/kennaookcape-grim/bl0506_Fraser_History.pdf\">PDF</a>]",
    "designation": "CGO",
    "data_availability": '',
    "instrumentation": [
        ],
    "latitude": "40.6833ºS",
    "longitude": "144.6894ºE",
    "elevation": "94 meters above sea level",
    "air_intake": "80 meters above ground level",
    "pi": "Dr Paul Krummel (paul.krummel@csiro.au)",
    "manager": "Dr Sarah Prior (sarah.prior@bom.gov.au)",
    "team": "Dr Blagoj Mitrevski (Blagoj.mitrevski@csiro.au); Dr Ann Stavert (ann.stavert@csiro.au); Dr Zoë Loh (zoe.loh@csiro.au); Dr Paul Fraser (paul.fraser@csiro.au)",
    "email": "capegrim@bom.gov.au",
    "address_name": "P.O. Box 346",
    "address_street": "Smithton,",
    "address_last_line": "Tasmania 7330, Australia",
    "related_links": [
        "http://www.bom.gov.au/inside/cgbaps/",
        "https://research.csiro.au/acc/capabilities/cape-grim-baseline-air-pollution-station/",
        "https://www.csiro.au/en/research/natural-environment/atmosphere/latest-greenhouse-gas-data",
        ],
    "funding": "The Kennaook/Cape Grim station is funded and managed by the Australian Bureau of Meteorology, with the AGAGE scientific program jointly managed with the Commonwealth Scientific and Industrial Research Organization (CSIRO). Support is also received from the Australian Department of Climate Change, Energy, the Environment and Water (DCCEEW), Refrigerant Reclaim Australia (RRA), and through the NASA Upper Atmospheric Research Program award to MIT (80NSSC21K1369) with a sub-award to CSIRO for Kennaook/Cape Grim AGAGE activities.",
},
{
    "directory": "ragged-point",
    "name": "Ragged Point",
    "country": "Barbados",
    "active": true,
    "square_images": [
        require('@/assets/img/stations/ragged-point/IMG_0787.jpeg'),
        require('@/assets/img/stations/ragged-point/IMG_0405.jpeg'),
        ],
    "tall_images": [

        ],
    "wide_images": [
        // require('@/assets/img/stations/ragged-point/IMG_0897.jpeg'),
        ],
    "blurb": "The northern tropical atmospheric research station at Ragged Point, Barbados is located on the eastern edge of the island with direct exposure to the Atlantic Ocean. It experiences generally easterly winds disrupted routinely by the passage of cyclones and anticyclones.",
    "description": 'The Barbados AGAGE station commenced operations in July 1978 on the East coast of Barbados just below the Ragged Point lighthouse. Following extensive analysis of optimal global locations by Georgia Institute of Technology indicating the requirement for a tropical location to constrain emissions estimates Barbados was ideal due to its isolation and NE trade winds providing clean background air masses. A 12-ft by 8-ft timber hut which had previously been constructed at Oregon Graduate Center, USA was shipped to Barbados and equipped with a microprocessor-controlled Hewlett Packard 5840A dual channel GC-ECDs.  A local person was recruited as a station technician and trained in the use of the HP instrumentation. They quickly mastered the technical challenges of running complex instrumentation in a remote location, not least of which was an unreliable electricity supply - especially during the early days.  Approximately every 3 months Dr. Peter Simmonds from the University of Bristol would arrive to service the instrumentation and install any improvements or upgrades to the GC. After a long day trying to work, sometimes with hut temperatures over 100°F (38°C) due to the failed AC, one redeeming fact was the option to retire to the nearby, now sadly shut, Sam Lord’s Castle hotel for a cool, relaxing rum punch!<br/><br/>\
        Due to the harsh salt air right on the coast, air-conditioning systems constantly needed to be replaced through rapid corrosion, and often GC parts needed replacement due to frequent power outages which had the unfortunate habit of burning out electrical components such as relays and sensitive electronic control boards. Gradually the program learnt to have spares on-site for every electronic board in anticipation of more failures, removing the need for a costly visit by a Hewlett Packard engineer. The electricity supply slowly became more reliable and the station operator became very adept at fixing unexpected problems without needing an emergency visit from staff from the University of Bristol, which now involved Dr. Simon O’Doherty. The station operator has continued to work at the site since its inception, with the added responsibility of maintaining the newer, more complex generations of automated GC. This includes the <a href=\"../instruments/gas-chromatography-multidetector-gc-md\">GC-MD</a> system installed in 1996 and the <a href="../instruments/medusa-gas-chromatography-mass-spectrometry-medusa-gc-ms">Medusa GC-MS</a>  system in 2005. Dr. Dickon Young has now joined Dr. O’Doherty at the University of Bristol and alongside the station operator is responsible for day-to-day operations at the site. <br/><br/>\
        The original wooden hut was replaced with a custom-built standard 20-ft by 8-ft intermodal container in 1996, concurrently with the deployment of the <a href=\"../instruments/gas-chromatography-multidetector-gc-md\">GC-MD</a> system. The complete container and laboratory space inside was constructed at Scripps Institution of Oceanography in California, USA and shipped out as a complete working environment. Insulated walls and a secondary shade-roof (removed by Hurricane Tomas in 2010) helped the AC to keep the temperatures stable but the salt, humidity and warmth of the coastal tropical site quickly started to rust the steel joists supporting the floor. A new, super-insulated laboratory with 20-ft by 12-ft galvanised steel frame was constructed locally on the island, and installed at the site in 2013. The frame is entirely clad with a fibreglass shell. Coupled with reliable modern AC systems, this keeps the moisture away from the steel and provides a long-term solution for upgrading instrumentation on site to achieve improved measurement of an ever-expanding list of gases in the AGAGE program.<br/><br/>\
        The AGAGE scientific program at Ragged Point is supervised by the University of Bristol.<br/><br/>\
        <a href="http://www.facebook.com/video.php?v=10152064051067804">Time-lapse video of new lab construction at Ragged Point</a>',
    "designation": "RPB",
    "data_availability": '',
    "instrumentation": [
        ],
    "latitude": "13.1651ºN",
    "longitude": "59.4321ºW",
    "elevation": "30 meters above sea level",
    "air_intake": "13 meters above ground level",
    "pi": "Dickon Young (dickon.young@bristol.ac.uk)",
    "manager": "Joseph “Peter” Sealy",
    "team": "Simon O’Doherty (s.odoherty@bristol.ac.uk)",
    "email": "dickon.young@bristol.ac.uk",
    "address_name": "AEROCE Research Facility",
    "address_street": "Ragged Point",
    "address_last_line": "St Philip, BB18032, Barbados",
    "related_links": [
        ],
    "funding": "AGAGE operations at Ragged Point are currently supported by the National Aeronautic and Space Administration (NASA) Grant 80NSSC21K1369 (to MIT, under sub-award S5608 to the University of Bristol) with additional funding from the National Oceanic and Atmospheric Administration (NOAA) Contract 1305M319CNRMJ0028 (to the University of Bristol).",
},
{
    "directory": "dongtan",
    "name": "Dongtan",
    "country": "China",
    "active": true,
    "square_images": [
        require('@/assets/img/stations/dongtan/dongtan.jpeg'),
        ],
    "tall_images": [

        ],
    "wide_images": [
    
        ],
    "blurb": "",
    "description": '',
    "designation": "DTN",
    "data_availability": '',
    "instrumentation": [
        ],
    "latitude": "31.5159ºN",
    "longitude": "121.9761ºE",
    "elevation": "3 meters above sea level",
    "air_intake": "8 meters above ground level",
    "pi": "Bo Yao (yaobo@fudan.edu.cn)",
    "manager": "",
    "team": "",
    "email": "",
    "address_name": "",
    "address_street": "",
    "address_last_line": "",
    "related_links": [
        ],
    "funding": "",
},
{
    "directory": "xichong",
    "name": "Xichong",
    "country": "China",
    "active": true,
    "square_images": [
        require('@/assets/img/stations/xichong/XCG_site.jpg'),
        ],
    "tall_images": [

        ],
    "wide_images": [
    
        ],
    "blurb": "",
    "description": '',
    "designation": "XCG",
    "data_availability": '',
    "instrumentation": [
        ],
    "latitude": "22.4831ºN",
    "longitude": "114.5572ºE",
    "elevation": "137 meters above sea level",
    "air_intake": "3 meters above ground level",
    "pi": "Lei ZHU (zhul3@sustech.edu.cn)",
    "manager": "Lei ZHU (zhul3@sustech.edu.cn)",
    "team": "Yuyang CHEN, Juan LI, Yali LI, Bo YAO, Honglong YANG, Xin YANG, Tzung-May FU",
    "email": "gorscac@sustech.edu.cn",
    "address_name": "CoE North 907",
    "address_street": "1088 Xueyuan Ave.",
    "address_last_line": "Shenzhen, Guangdong, China, 518055",
    "related_links": [
        ],
    "funding": "The XCG station is a joint effort between Southern University of Science and Technology (SUSTech) and the Meteorological Bureau of Shenzhen (MBS). The station is funded by the Shenzhen city and Guangdong province through SUSTech.",
},
{
    "directory": "taunus",
    "name": "Taunus",
    "country": "Germany",
    "active": true,
    "square_images": [
        require('@/assets/img/stations/taunus/DJI_0483.jpeg'),
        require('@/assets/img/stations/taunus/35A0289.jpeg'),
        ],
    "tall_images": [

        ],
    "wide_images": [
        // require('@/assets/img/stations/taunus/SDC10116.jpg'), 
        ],
    "blurb": "The Taunus Observatory is located on the second highest peak (Kleiner Feldberg; 825 m.a.s.l.) in the Taunus mountain region in central Germany.",
    "description": 'The station is situated in close proximity to the regionally polluted Rhine-Main region and 20 km to the north-west of the city of Frankfurt am Main. Due to its location in central Europe and proximity to anthropogenic greenhouse gases and ozone depleting substance source regions, the site is well suited for European pollution studies.',
    "designation": "TOB",
    "data_availability": '',
    "instrumentation": [
        ],
    "latitude": "50.2219ºN",
    "longitude": "8.4464ºE",
    "elevation": "825 meters above sea level",
    "air_intake": "12 meters above ground level",
    "pi": "Prof. Andreas Engel (an.engel@iau.uni-frankfurt.de)",
    "manager": "",
    "team": "Thomas Wagenhäuser (wagenhaeuser@iau.uni-frankfurt.de)",
    "email": "",
    "address_name": "",
    "address_street": "",
    "address_last_line": "",
    "related_links": [
        ],
    "funding": "Taunus Observatory is funded by BMBF through the ACTRIS-D project (FKZ 01LK2001I). Halocarbon measurements are funded by the European Commission as part of the PARIS project (GA 101081430 ).",
},
{
    "directory": "monte-cimone",
    "name": "Monte Cimone",
    "country": "Italy",
    "active": true,
    "square_images": [
        require('@/assets/img/stations/monte-cimone/MtCimone.jpg'),
        ],
    "tall_images": [

        ],
    "wide_images": [
        // require('@/assets/img/stations/monte-cimone/MtCimone.jpg'),
        ],
    "blurb": 'The Italian Climate Observatory "Ottavio Vittori" at Mount Cimone (ICO-OV) is located on the highest peak of the Northern Apennines that mark the border line of two different climate regions: the continental Europe (northwards) and the Mediterranean Basin (southwards).',
    "description": 'The observations of atmospheric composition conducted at the ICO-OV, provide useful information for evaluating the role of regional and long-range transport processes in modifying the tropospheric background conditions of the Mediterranean basin/Southern Europe. Due to the completely free horizon, high altitude and great distance from major pollution sources, ICO-OV represents a strategic platform to study the chemical-physical characteristics and climatology of the free troposphere in the South Europe and North Mediterranean basin. Moreover, this long-term monitoring activity helps in quantifying the existing trend of climate-altering substances and atmospheric pollutants.<br/><br/>\
                   Continuous monitoring of climate-altering compounds (trace gases and aerosol), solar radiation as well as meteorological parameters are carried out since 1996 by ISAC-CNR (Institute of Atmospheric Sciences and Climate of the National Reaserach Council). Measurements of atmospheric concentration of a wide range of halogenated VOCs started in 2003 by University of Urbino, Italy, with the installation of a commercial ADS-GC-MS system (Adsorption-Desorption System coupled with Gas-Chromatograph and a Mass Spectrometer analyzer), fully automated for continuous operation.<br/><br/>\
                   The ICO-OV is classified as a global station by the Global Atmosphere Watch (GAW) program by the World Meteorological Organization (WMO).',
    "designation": "CMN",
    "data_availability": '',
    "instrumentation": [
        ],
    "latitude": "44.1932ºN",
    "longitude": "44.1932ºN",
    "elevation": "2165 meters above sea level",
    "air_intake": "7 meters above ground level",
    "pi": "Michela Maione (michela.maione@uniurb.it)",
    "manager": "Jgor Arduini (jgor.arduini@uniurb.it)",
    "team": "Paolo Bonasoni (p.bonasoni@isac.cnt.it); Francescopiero Calzolari (f.calzolari@isac.cnr.it); Paolo Cristofanelli (p.cristofanelli@isac.cnr.it), Angela Marinoni (a.marinoni@isac.cnr.it)",
    "email": "",
    "address_name": "",
    "address_street": "",
    "address_last_line": "",
    "related_links": [
        "https://cimone.isac.cnr.it/",
        ],
    "funding": "Monte Cimone is funded by the CNR-ISAC (National Research Council of Italy Institute of Atmospheric Sciences and Climate) and PON PER-ACTRIS-IT (Programma Operativo Nazionale Ricerca e Innovazione 2014-2020, Potenziamento della componente italiana dell'Infrastruttura di Ricerca Aerosol, Clouds and Trace Gases Research).",
},
{
    "directory": "mace-head",
    "name": "Mace Head",
    "country": "Ireland",
    "active": true,
    "square_images": [
        // require('@/assets/img/stations/mace-head/MaceHead_oceanside_web_0.jpg'),
        // require('@/assets/img/stations/mace-head/MaceHead_best_web.jpg'),
        require('@/assets/img/stations/mace-head/MHD_9.jpg'),
        require('@/assets/img/stations/mace-head/MHD_10.jpg'),
        require('@/assets/img/stations/mace-head/MHD_11.jpg'),
        require('@/assets/img/stations/mace-head/MHD_12.jpg'),
        require('@/assets/img/stations/mace-head/MHD_13.jpg'),
        require('@/assets/img/stations/mace-head/MHD_14.jpg'),
        require('@/assets/img/stations/mace-head/MHD_15.jpg'),
        ],
    "tall_images": [

        ],
    "wide_images": [
    
        ],
    "blurb": "The Mace Head station is located on the west coast of Ireland. Its exposure to the North Atlantic Ocean make it an ideal location to study both natural and man-made trace constituents in marine and continental air masses.",
    "description": 'The Mace Head, Ireland station is a baseline station in the WMO network of Global Atmospheric Watch (GAW) stations. GAGE/AGAGE operations began here in 1987 (from 1978 to 1983, ALE operated at <a href="../stations/adrigole">Adrigole</a> at 52ºN, 10ºW). Measurements of atmospheric CFC-11, CFC-12, CH<sub>3</sub>CCl<sub>3</sub>, CCl<sub>4</sub>, and N<sub>2</sub>O started in 1978. Measurements of CFC-113, CHCl<sub>3</sub> and CH<sub>4</sub> started in 1987, and of CO and H<sub>2</sub> began in 1995. The first AGAGE automated gas chromatograph-mass spectrometer (GC-MS), based on a Finnigan Magnum Ion Trap coupled to a custom-built (University of Bristol) adsorption-desorption system (<a href="../instruments/gas-chromatography-mass-spectrometry-ads-gc-ms">ADS GC-MS</a>), was installed at the Mace Head, Ireland AGAGE station in October 1994. It was replaced by the Agilent 5973 MS in 1998 and this was later retired in December 2004, after a new state of the art <a href="../instruments/medusa-gas-chromatography-mass-spectrometry-medusa-gc-ms">Medusa GC-MS</a>  system was installed and started operation in November 2003. The AGAGE GC-MS systems measure a wide range of CFCs, HCFCs, HFCs, PFC, halons, and halocarbons.',
    "designation": "MHD",
    "data_availability": '',
    "instrumentation": [
        ],
    "latitude": "53.3267ºN",
    "longitude": "9.9046ºW",
    "elevation": "8 meters above sea level",
    "air_intake": "11 meters above ground level",
    "pi": "Simon O'Doherty (s.odoherty@bristol.ac.uk)",
    "manager": "Gerry Spain (gerard.spain@nuigalway.ie)",
    "team": "Dickon Young, Kieran Stanley",
    "email": "",
    "address_name": "Carna, Co. Galway H91N2R2, Ireland",
    "address_street": "",
    "address_last_line": "",
    "related_links": [
        ],
    "funding": "Atmospheric gas measurements at Mace Head are supported by research grants from the Department of Energy, Security and Net Zero (DESNZ), contract number 5488/11/2021 in the UK; and the National Aeronautic and Space Administration Grants (NASA), sub-award S5608 PO# - 752393.",
},
{
    "directory": "cape-ochi-ishi",
    "name": "Cape Ochi-ishi",
    "country": "Japan",
    "active": true,
    "square_images": [
        require('@/assets/img/stations/cape-ochi-ishi/Ochiishi_J3V5844.jpg'),
        //require('@/assets/img/stations/cape-ochi-ishi/Ochiishi_MG_3689.jpg'),    
        // todo crystaltal get more images from share drive    
        ],
    "tall_images": [

        ],
    "wide_images": [
    
        ],
    "blurb": "Cape Ochi-ishi Station is located at the eastern coast of Hokkaido, at the base of Nemuro peninsula.",
    "description": 'The city of Nemuro (located ~20 km east, population 35,000) is the nearest city. Cape Ochi-ishi has a considerably cold climate with average yearly temperature of about 5°C. Most of the cape is marshland, designated as a nature preserve; the monitoring station is located at the southern tip of the cape, and is approximately 50 m above sea level.<br/><br/>\
                    Hourly measurements of halocarbons have been performed at Cape Ochiishi since 2006. A fully automated preconcentration/gas chromatograph/mass spectrometer system measures ~50 trace gases, including halocarbons.',
    "designation": "COI",
    "data_availability": '',
    "instrumentation": [
        ],
    "latitude": "43.1603°N",
    "longitude": "145.4973°E",
    "elevation": "42 meters above sea level",
    "air_intake": "52 meters above ground level",
    "pi": "Takuya Saito (saito.takuya@nies.go.jp)",
    "manager": "Motoki Sasakawa (sasakawa.motoki@nies.go.jp)",
    "team": "",
    "email": "",
    "address_name": "",
    "address_street": "",
    "address_last_line": "",
    "related_links": [
        ],
    "funding": "Halocarbon observations at Cape Ochi-ishi are made as a part of the global environmental monitoring program of National Institute for Environmental Studies (NIES).",
},
{
    "directory": "hateruma",
    "name": "Hateruma",
    "country": "Japan",
    "active": true,
    "square_images": [
        require('@/assets/img/stations/hateruma/Hateruma_MG_6637.jpg'),
        //require('@/assets/img/stations/hateruma/Hateruma_mg_6519.jpg'),  
        // TODO get more images from share drive, email sent to Anita for this  
        ],
    "tall_images": [

        ],
    "wide_images": [
    
        ],
    "blurb": "The Hateruma Global Environment Monitoring Station is located at the southern end of Japan. The station was established in 1992 to monitor the long-term trends of greenhouse gases (GHGs) in Eastern Asia.",
    "description": 'Hateruma Island, a small sub-tropical island and part of the Sakishima Islands, is located at more than 1000 km from mainland Japan. The wind is northerly to northeasterly in autumn-winter and southerly to southeasterly in spring-summer. The annual mean wind speed at the top of the tower (46.5 m above sea level) is about 8 m/s. The surface temperature is about 24ºC and the annual rainfall is about 1900 mm. Hourly measurements of halocarbons have been performed at Hateruma since 2004. A fully automated preconcentration/gas chromatograph/mass spectrometer system measures ~50 trace gases, including halocarbons.',
    "designation": "HAT",
    "data_availability": '',
    "instrumentation": [
        ],
    "latitude": "24.0607ºN",
    "longitude": "123.8094ºE",
    "elevation": "10 meters above sea level",
    "air_intake": "37 meters above ground level",
    "pi": "Takuya Saito (saito.takuya@nies.go.jp)",
    "manager": "Motoki Sasakawa (sasakawa.motoki@nies.go.jp)",
    "team": "",
    "email": "",
    "address_name": "",
    "address_street": "",
    "address_last_line": "",
    "related_links": [
        ],
    "funding": "Halocarbon observations at Hateruma are made as a part of the global environmental monitoring program of National Institute for Environmental Studies (NIES).",
},
{
    "directory": "mt-mugogo",
    "name": "Mt. Mugogo",
    "country": "Rwanda",
    "active": true,
    "square_images": [
        require('@/assets/img/stations/mt-mugogo/Mugogo3.jpg'),
        ],
    "tall_images": [
        // require('@/assets/img/stations/mt-mugogo/Mugogo-AGAGE-Rwanda-web_1.png'),
        // require('@/assets/img/stations/mt-mugogo/Mugogo4-web.png'),
        ],
    "wide_images": [
    
        ],
    "blurb": "MIT and the Government of Rwanda have collaborated to build this world-class observatory on Mt. Mugogo measuring climate change and the atmospheric species forcing climate change.",
    "description": 'This AGAGE station, the first in all of Africa, is located on the summit of Mt. Mugogo – a rural area about 70 km from Kigali, the capital city of Rwanda, and 13 km away from the nearest town of Musanze. Seven-day back trajectories of air masses sampled at Mt. Mugogo stretch Northeast as far as Saudi Arabia and the Indian West Coast. In the Southeast direction the air masses come from as far away as Madagascar. The air masses from the East travel over the East African region characterized by a high population density, urban areas and intense agricultural activities. West of Mt. Mugogo, air parcels come mainly from the equatorial forests in the Democratic Republic of Congo and Central African Republic. Measurements taken at Mount Mugogo are also occasionally influenced by local sources identified by enhanced black carbon aerosol and carbon monoxide levels.<br/><br/>\
                    The Climate Observatory Project evolved from discussions in 2008-2009 between HE President Paul Kagame, his ministers, and the MIT administration. The observatory is now led and run by Rwandan researchers, advised by MIT scientists.<br/><br/>\
                    Through this observatory program, Rwandan researchers are gaining the capability using state of the art computer models to calculate regional greenhouse gas sources and sinks, climate change, and air pollution. The observatory data is also used for University of Rwanda (UR) student thesis projects, and MIT is working with UR faculty on their new Atmospheric and Climate Science Master’s degree and undergraduate courses. The Climate Observatory Project is also aiding in development of air pollution measurements for human health objectives, led by the Rwanda Environmental Management Authority (REMA). The Climate Observatory Project is under Rwanda Space Agency.',
    "designation": "RCO",
    "data_availability": 'The data from Mt. Mugogo will be added to the AGAGE website once internally validated and the first data published in peer-reviewed journals (expected in 2018). A preview can be found in the 2017 MIT doctoral thesis of Dr. J. Gasore (download from <a href="http://web.mit.edu/rprinn">web.mit.edu/rprinn</a>)',
    "instrumentation": [
        '<strong>Picarro© G2401 CRDS</strong> (with Earth Networks© drier) measuring CO<sub>2</sub>, CH<sub>4</sub>, CO and H<sub>2</sub>O (for water vapor correction)',
        '<strong>Picarro© G5205 CRDS</strong> (with Earth Networks© drier) measuring N<sub>2</sub>O and H<sub>2</sub>O (for water vapor correction)',
        '<strong>Teledyne© API T400 UV Spectrometer</strong> measuring O<sub>3</sub>',
        '<strong>Magee Scientific© AE33 Aethalometer</strong> measuring Black Carbon aerosols',
        '<strong>Medusa-GC (Agilent© 7890B)-MS (Agilent© 5977A)</strong> measuring ~ 50 trace gases, with AGAGE tertiary calibration standards on site. To extend the lifetime of the tertiary standards, a modified RIX© oil-free air compressor system is used to prepare quaternary standards on site, that are calibrated using the tertiary standards.',
        '<strong>Vaisala© WXT520 Weather Station</strong> measuring wind speeds, wind direction, humidity, temperature, pressure and precipitation',
        '<strong>Licor© LI-200 pyranometer</strong> measuring solar radiation',
        ],
    "latitude": "1.586°S",
    "longitude": "29.566°E",
    "elevation": "2590 meters above sea level",
    "air_intake": "35 meters above ground level",
    "pi": "Dr. Didier Ntwali (dntwali@space.gov.rw)",
    "manager": "",
    "team": "Mr. Jacques Nshuti, Mr. Emmanuel Iradukunda, Mr. Eric Byiringiro, Mr. Olivier Shyaka, Mr. Gaston Munyampundu",
    "email": "",
    "address_name": "",
    "address_street": "",
    "address_last_line": "",
    "related_links": [
        ],
    "funding": "The atmospheric greenhouse gas, air pollutants and meteorological parameters measurements at Mount Mugogo are funded and managed by Rwanda Space Agency.",
},
{
    "directory": "gosan",
    "name": "Gosan",
    "country": "South Korea",
    "active": true,
    "square_images": [
        require('@/assets/img/stations/gosan/KakaoTalk_Photo_2023-09-12-10-10-56 010.jpeg'),
        require('@/assets/img/stations/gosan/1558437080392.jpeg'),
        require('@/assets/img/stations/gosan/Gosan_station_KMA_NIMS_photo3.jpg'),
        ],
    "tall_images": [
        // require('@/assets/img/stations/gosan/IMG_8143.jpeg'),
        // require('@/assets/img/stations/gosan/P9030384.jpg'),
        // require('@/assets/img/stations/gosan/F7F04920-E34F-4748-89BD-DDAF4A86B57B.jpg'),
        // require('@/assets/img/stations/gosan/KakaoTalk_Photo_2023-09-12-10-10-56 009.jpeg'),
        ],
    "wide_images": [
        // require('@/assets/img/stations/gosan/Gosan_station_KMA_NIMS_photo1.jpg'),
        // require('@/assets/img/stations/gosan/Gosan_station_KMA_NIMS_photo2.jpg'),
        ],
    "blurb": "The Gosan GAW Regional Station (Global Atmosphere Watch, program of the World Meteorological Organization) is located on the south-western tip of Jeju Island (Republic of Korea), facing the East China Sea. The station rests at the top of a 72 m cliff, about 100 km south of the Korean peninsula, 500 km northeast of Shanghai, China, and 250 km west of Kyushu, Japan.",
    "description": 'Jeju Island is regarded as one of the cleanest areas in South Korea, with low emissions of air pollutants. As it is known, Gosan Station is one of the most important sites for monitoring the outflows from the Asian continent. Weekly flask sampling began in 1990 for monitoring the atmospheric CO<sub>2</sub> concentrations and contributing to the GLOBALVIEW-CO<sub>2</sub> data product. Continuous, <em>in situ</em> observations for CO<sub>2</sub> using a LOFLO system have been tracked since 2007. <br/><br/>\
                    The Medusa GC-MS system was established at Gosan in 2007 and has been operated as part of the AGAGE network to take high-precision, high-frequency measurements for a wide range of HCFCs, HFCs, PFCs, Halons and other halocarbons. <em>In situ</em> data for atmospheric CH<sub>4</sub> and CO are also obtained at Gosan by FID-GC and NDIR systems, respectively. For better understanding for natural variability of atmospheric CO<sub>2</sub> and CH<sub>4</sub>, <em>in situ</em> hourly measurements for δ<sup>13</sup>C of CO<sub>2</sub> and CH<sub>4</sub> were started in 2013 using cavity ring-down spectroscopy.',
    "designation": "GSN",
    "data_availability": '',
    "instrumentation": [
        ],
    "latitude": "33.2924ºN",
    "longitude": "126.1616ºE",
    "elevation": "72 meters above sea level",
    "air_intake": "17 meters above ground level",
    "pi": "Prof. Sunyoung Park( sparky@knu.ac.kr)",
    "manager": "Jaegeun Yun (kkoke08@gmail.com)",
    "team": "",
    "email": "",
    "address_name": "",
    "address_street": "",
    "address_last_line": "",
    "related_links": [
        ],
    "funding": "Observations at the Gosan station are supported by the Basic Science Research Program through the National Research Foundation of Korea (NRF) funded by the Ministry of Education (no. NRF-2016R1A2B2010663).",
},
{
    "directory": "zeppelin",
    "name": "Zeppelin",
    "country": "Svalbard",
    "active": true,
    "square_images": [
        require('@/assets/img/stations/zeppelin/ZeppStatMay2014c_web.png'),
        //require('@/assets/img/stations/zeppelin/ZepIndoor_web.png'),
        ],
    "tall_images": [

        ],
    "wide_images": [
    
        ],
    "blurb": "Zeppelin Observatory is located in Ny-Ålesund, one of the world's northernmost human settlements. The unique location of this station makes it an ideal platform for the monitoring of global atmospheric change and long-range pollution transport.",
    "description": 'The Zeppelin Observatory is located in the Arctic on Zeppelin Mountain, 474 meters a.s.l, close to the town of Ny-Ålesund in the island archipelago of Svalbard. Zeppelin Mountain is an undisturbed environment, far away from major pollution sources. Most of the time it is above the local inversion layer, so influence from any local pollution sources - such as from the nearby community of Ny-Ålesund - is also limited.<br/><br/>\
                    Svalbard is very sparsely populated, and Ny-Ålesund is no exception: it has ~35 permanent residents, with up to 150 additional scientists during the summer peak season. Large areas of Svalbard (about 60%) are covered with glaciers, which at several places stretch well out into the sea.<br/><br/>\
                    <strong>The latest data collected from the Ny-Ålesund Observatory:<strong><br /><a href="http://ebas.nilu.no/">Ny-Ålesund Observatory Data.</a>',
    "designation": "ZEP",
    "data_availability": '',
    "instrumentation": [
        ],
    "latitude": "78.9072ºN",
    "longitude": "11.8867ºE",
    "elevation": "474 meters above sea level",
    "air_intake": "15 meters above ground level",
    "pi": "Dr. Chris Lunder (crl@nilu.no)",
    "manager": "Ove Hermansen (oh@nilu.no)",
    "team": "Ove Hermansen, Norbert Schmidbauer",
    "email": "",
    "address_name": "",
    "address_street": "",
    "address_last_line": "",
    "related_links": [
        ],
    "funding": "The halocarbon measurements at Zeppelin Observatory are funded by the Norwegian Environment Agency.",
},
{
    "directory": "jungfraujoch",
    "name": "Jungfraujoch",
    "country": "Switzerland",
    "active": true,
    "square_images": [
        require('@/assets/img/stations/jungfraujoch/sphinx-jungfraujoch-aletschletscher-sonnenuntergang_1.jpg'),
        require('@/assets/img/stations/jungfraujoch/JungfraujochRestaurantResearchstationSphinx_web_0.jpg'),
        // todo get more photos but not the one with a person in it -  crystal
        // let Anitia know what was the other photo with a person in it
        ],
    "tall_images": [

        ],
    "wide_images": [
    
        ],
    "blurb": "The high altitude research station Jungfraujoch is situated on a mountain saddle in the central Swiss Alps between the Jungfrau (4158 m above sea level) in the West and the Moench (4099 m above sea level) in the East.",
    "description": 'The station is well suited for long-term ground-based monitoring of trace gas concentrations trends in the free troposphere. Due to its location in central Europe and its proximity to anthropogenic greenhouse gas source regions it is also an appropriate study site for European regional source allocations during pollution events.',
    "designation": "JFJ",
    "data_availability": '',
    "instrumentation": [
        ],
    "latitude": "46.5478ºN",
    "longitude": "7.9859ºE",
    "elevation": "3571 meters above sea level (visitor terrace), 3559 m.a.s.l. (rocky ground)",
    "air_intake": "1.5 meters above rocky ground level (3561 m.a.s.l.)",
    "pi": "Dr. Stefan Reimann (stefan.reimann@empa.ch)",
    "manager": "Dr. Martin Vollmer (martin.vollmer@empa.ch)",
    "team": "",
    "email": "",
    "address_name": "High Altitude Research Station Jungfraujoch",
    "address_street": "",
    "address_last_line": "3801 Jungfraujoch, Switzerland",
    "related_links": [
        "https://www.hfsjg.ch/en/jungfraujoch/"
        ],
    "funding": "Financial support for the AGAGE measurements at Jungfraujoch is provided by the Swiss National Programs HALCLIM and CLIMGAS-CH (Swiss Federal Office for the Environment [FOEN]) and by the International Foundation High Altitude Research Stations Jungfraujoch and Gornergrat (HFSJG)",
},
{
    "directory": "tacolneston",
    "name": "Tacolneston",
    "country": "United Kingdom",
    "active": true,
    "square_images": [
        require('@/assets/img/stations/tacolneston/IMG_2167.jpg'),
        //require('@/assets/img/stations/tacolneston/IMG_3003.jpg'),
        ],
    "tall_images": [
        // require('@/assets/img/stations/tacolneston/IMG_2170.jpg'),
        // require('@/assets/img/stations/tacolneston/IMG_2708.jpg'),
        ],
    "wide_images": [
    
        ],
    "blurb": "Located in the east of England, the Atmospheric Measurement Station at Tacolneston, Norfolk, is ideally positioned to observe air masses which have travelled across central England.",
    "description": 'Tacolneston also receives air flow from London located 150 km to the south west of the site. In this way Tacolneston is optimally located to observe and constrain emissions from the UK. Tacolneston is part of the DECC Network, which also includes measurements from Mace Head (Co. Galway, Ireland), Ridge Hill (Herefordshire, UK), Bilsdale (North Yorkshire, UK) and Heathfield (East Sussex, UK). This enables emissions from separate regions of the UKs devolved administration to be constrained (England, Wales, Scotland and N. Ireland).<br/><br/>\
    Measurements of CO<sub>2</sub> and CH<sub>4</sub> are made using a Picarro G2301 cavity ringdown spectroscopy analyser. A Los Gatos Research (LGR) Enhanced Performance N<sub>2</sub>O/CO analyser (model no. 913-0015-810) has also been operational at the site since 2016. Both of these instruments utilise laser-based optical absorption techniques, enabling frequent measurement of each species (i.e. measurements every few seconds). Over 50 other trace gases are measured using a Medusa GC-MS instrument, including key greenhouse gases and ozone depleting substances. Since 2020, measurements of <sup>222</sup>Rn have also been made using an ANSTO (Australian Science and Technology Organization) analyser, providing additional context regarding the recent history of the air sampled at Tacolneston.',
    "designation": "TAC",
    "data_availability": '',
    "instrumentation": [
        ],
    "latitude": "52.5188°N",
    "longitude": "1.1387°E",
    "elevation": "",
    "air_intake": "185 meters above ground level (CH4 and N2O also at 54, 100 m.a.g.l.)",
    "pi": "Prof. Simon O’Doherty, Dr. Kieran Stanley (k.m.stanley@bristol@ac.uk)",
    "manager": "",
    "team": "",
    "email": "",
    "address_name": "School of Chemistry, University of Bristol,",
    "address_street": "",
    "address_last_line": "Cantocks Close, Bristol, BS8 1TS",
    "related_links": [
        'https://www.bristol.ac.uk/chemistry/research/acrg/current/decc.html'
        ],
    "funding": "",
},
{
    "directory": "trinidad-head",
    "name": "Trinidad Head",
    "country": "United States",
    "active": true,
    "square_images": [
        require('@/assets/img/stations/trinidad-head/HM1H108687.jpg'),
        require('@/assets/img/stations/trinidad-head/20220525_151459-rotated-cropped.jpg'),
        require('@/assets/img/stations/trinidad-head/20220525_144033.jpg'),
        require('@/assets/img/stations/trinidad-head/20230928_173256-rotated-cropped.jpg'),
        require('@/assets/img/stations/trinidad-head/20230928_173312-rotated-cropped.jpg'),
        ],
    "tall_images": [
        // require('@/assets/img/stations/trinidad-head/20220525_141554.jpg'),
        ],
    "wide_images": [
        // require('@/assets/img/stations/trinidad-head/20220525_151459-rotated-cropped.jpg'),
        // require('@/assets/img/stations/trinidad-head/20230928_173256-rotated-cropped.jpg'),    
        ],
    "blurb": "The Trinidad Head station is located atop Trinidad Head (elevation ~120 meters) on the remote north coast of California, approximately 30 km north of Eureka.",
    "description": 'The AGAGE operates two in-situ instruments there. The <a href="../instruments/gas-chromatography-multidetector-gc-md">GC-MD instrument</a> started measuring atmospheric CFC-11, CFC-12, CFC-113, CH<sub>3</sub>CCl<sub>3</sub>, CCl<sub>4</sub>, N<sub>2</sub>O, CH<sub>4</sub>, and CHCl<sub>3</sub> in 1995. Air samples are collected from a tower used by the US Coast Guard for marine radio communications. The <a href="../instruments/medusa-gas-chromatography-mass-spectrometry-medusa-gc-ms">Medusa GC-MS instrument</a> which measures more than 50 greenhouse gases and ozone depleting substances was installed in May 2005.<br/><br/>\
                    <a href="https://www.esrl.noaa.gov/gmd/">NOAA’s Global Monitoring Division</a> also measures a range of ozone depleting substances and (until 2017) greenhouse gases at Trinidad Head.',
    "designation": "THD",
    "data_availability": '',
    "instrumentation": [
        ],
    "latitude": "41.0541ºN",
    "longitude": "-124.151ºE",
    "elevation": "107 meters above sea level",
    "air_intake": "15 meters above ground level",
    "pi": "Prof. Ray F. Weiss (rfweiss@ucsd.edu); Dr. Jens Mühle (jmuhle@ucsd.edu)",
    "manager": "Randy Dickau",
    "team": "Jooil Kim, Chris Harth, Roland Schmidt, Jen Gonzalez",
    "email": "",
    "address_name": "Dr. Jens Mühle, Mail Code 0244",
    "address_street": "Scripps Institution of Oceanography",
    "address_last_line": "La Jolla, California, 92093, USA",
    "related_links": [
        ],
    "funding": "AGAGE operations at Trinidad Head are funded by the National Aeronautics and Space Administration (NASA) in the USA.",
},
// ************************
// INACTIVE SATATIONS
// ************************
{
    "directory": "shangdianzi",
    "name": "Shangdianzi",
    "country": "China",
    "active": false,
    "square_images": [
        require('@/assets/img/stations/shangdianzi/SDZ-279_web.jpg'),
        //require('@/assets/img/stations/shangdianzi/Shangdianzi_lab_web.jpg'),
        ],
    "tall_images": [
        // require('@/assets/img/stations/shangdianzi/SDZ-162_web.png'),
        // require('@/assets/img/stations/shangdianzi/shangdianzi_station_web.png'),
        ],
    "wide_images": [
    
        ],
    "blurb": "The Shangdianzi GAW Regional Station (Global Atmosphere Watch, program of the World Meteorological Organization) is located 100km northeast of urban Beijing. This station is part of the domain of the China Meteorological Administration (CMA), and it is jointly operated by the Beijing Meteorological Bureau (BMB) and Meteorological Observation Centre (MOC) of China Meteorological Administration (CMA).",
    "description": 'The first in-situ measurement of ODSs and solvents in China was performed by GC-ECDs at the Shangdianzi station, and they have continued measurements since 2006. As one of the partners of SOGE-A, Shangdianzi measurement is attached to the SOGE and linked to the AGAGE network. In-situ atmospheric CO<sub>2</sub>/CH<sub>4</sub> measurements by Picarro CRDS were implemented in 2009, and in-situ CH<sub>4</sub>/CO/N<sub>2</sub>O/SF<sub>6</sub> measurements by GC-FID+ECD were implemented in early 2010. A new state of the art <a href="../instruments/medusa-gas-chromatography-mass-spectrometry-medusa-gc-ms">Medusa GC-MS</a> system was installed and started operation in May 2010 to measure CFCs, HCFCs, HFCs, PFCs, halons, and halocarbons.<br/><br/>\
                    The Shangdianzi station has experienced pollution events, influenced by air masses from Beijing and other industrialized areas in the North China Plains, and clean air masses, from remote areas such as Siberia, Mongolia, and the Chinese province Inner Mongolia. The station was sensitive to emissions from large areas of north-east Asia, actually representing about 45% of the Chinese population. Influenced by monsoon and local valley topography, the prevailing wind sectors for the whole year are NE/ENE/E (background sectors) and SW/WSW/W (urban sectors).<br/><br/>\
                    The scientifically defensible data from Shangdianzi are produced with an approach consistent with SOGE/AGAGE guidelines and recognized QA/QC procedures. Almost all of the scientific publications and assessment reports related to China and Asian halogenated GHGs are supported by the Shangdianzi observational data.',
    "designation": "",
    "data_availability": '',
    "instrumentation": [
        ],
    "latitude": "40ºN",
    "longitude": "117ºE",
    "elevation": "293 m",
    "air_intake": "383 m",
    "pi": "Dr. Bo Yao (yaob@cma.gov.cn)",
    "manager": "",
    "team": "",
    "email": "",
    "address_last_line": "Zhongguancun Nandajie 46, Haidian District, Beijing, 100081",
    "address_name": "",
    "address_street": "",
    "address_last_line": "",
    "related_links": [
        ],
    "funding": "The Shangdianzi Station is funded and managed by the China Meteorological Administration and the halogenated program is funded by the National Nature Science Foundation of China, National Basic Research Program of China “973”, MOST International Cooperative Project, and CMA operation funding, among others.",
},
{
    "directory": "adrigole",
    "name": "Adrigole",
    "country": "Ireland",
    "active": false,
    "square_images": [
        require('@/assets/img/stations/adrigole/Hungry_hill_web.jpg'),
        ],
    "tall_images": [

        ],
    "wide_images": [
    
        ],
    "blurb": "The station at Adrigole, Ireland is no longer used for sampling.",
    "description": 'In 1987, it was replaced by the station at <a href="../stations/mace-head">Mace Head, Ireland.</a>',
    "designation": "",
    "data_availability": '',
    "instrumentation": [
        ],
    "latitude": "52ºN",
    "longitude": "10ºW",
    "elevation": "",
    "air_intake": "",
    "pi": "",
    "manager": "",
    "team": "",
    "email": "",
    "address_name": "",
    "address_street": "",
    "address_last_line": "",
    "related_links": [
        ],
    "funding": "",
},
{
    "directory": "cape-meares-oregon",
    "name": "Cape Meares, Oregon",
    "country": "United States",
    "active": false,
    "square_images": [
        require('@/assets/img/stations/cape-meares-oregon/rand02287_1024web.jpg'),
        require('@/assets/img/stations/cape-meares-oregon/cape-meares.jpeg'),
        ],
    "tall_images": [

        ],
    "wide_images": [
    
        ],
    "blurb": "The station at Cape Meares, Oregon is no longer used for sampling.",
    "description": 'In 1995, it was replaced by the station at <a href="../stations/trinidad-head">Trinidad Head, California.</a>',
    "designation": "",
    "data_availability": '',
    "instrumentation": [
        ],
    "latitude": "45ºN",
    "longitude": "124ºW",
    "elevation": "",
    "air_intake": "",
    "pi": "",
    "manager": "",
    "team": "",
    "email": "",
    "address_name": "",
    "address_street": "",
    "address_last_line": "",
    "related_links": [
        ],
    "funding": "",
},
]