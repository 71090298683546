<template>
  <b-container fluid>
    <b-row align-v="center" class="intro-blurb p-2">
      <b-col sm="12" md="6" lg="5" style="max-width: 660px;">
        <BCard border-variant="secondary"
            no-body
            style="max-width: 620px;">
          <BCardText>
            <BImg fluid :src="currentImage" :alt="currentFooter" class="station-img" />
          </BCardText>
        </BCard>
      </b-col>
      <b-col sm="12" md="6" lg="7">
        <h1 style="color: black; !important">
          The Advanced Global Atmospheric Gases Experiment (AGAGE) has been measuring the composition of the global atmosphere continuously since 1978.
        </h1>

        <BButton class="btn-info" size=sm right href="./global-network">Learn more about our global network</BButton>
      </b-col>

    </b-row>
  </b-container>
</template>

<script>

import authMixins from '@/components/app/authMixins'
import { stations } from '@/init/data/stations'

export default {

  name: 'SiteIntro',

  mixins: [ authMixins ],

  data () {
    return {
      stations: stations.filter(station => {
                // use active stations on this page
                if (station.active) return station
              }),
      currentImageIndex: 0,
      timerCount: 4,
      timerIncrement: 4,
    }
  },
  computed: {
    currentImage() {
      return this.stations[this.currentImageIndex].square_images[0];
    },
    currentFooter() {
      return this.stations[this.currentImageIndex].name + ', ' + this.stations[this.currentImageIndex].country;
    },
    currentStationPath() {
      return '/stations/' +  this.stations[this.currentImageIndex].directory; 
    },
  },
  watch: {
    timerCount: {
      handler(value) {
        if (value > 0) {
            setTimeout(() => {
                this.timerCount--;
            }, 1000);
        } else {
          this.timerCount = this.timerIncrement;
          // rotate the station picture
          if (this.currentImageIndex == this.stations.length - 1) {
            this.currentImageIndex = 0;
          } else {
            this.currentImageIndex++;
          }
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
      deep: false,
    },
  },
}
</script>

<style scoped>
.intro-blurb {
  background-color: #d7fdde;
}

h1 {
  font-size: 2rem;
}

a {
  color: white;
  text-decoration: none;
  font-weight: 500;
  font-size: 1.1em;
}

a:hover {
  color: white;
  text-decoration: underline;
}

.btn-info {
  background-color: #2F5779;
  border-color: #2F5779;
  color: #FFFFFF;
  font-size: 1.1em;
}

.card {
  text-align: center;
  padding: 0px;
  background-color: #2F5779; 
  color: white;
}

.card-body {
  padding: 0px ! important;
}

p.card-text {
  padding: 0px;
  margin: 0px;
}

.station-img {
    width: 100%;
    height: 300px;
    object-fit: cover;
}
</style>