import * as VeeValidate from 'vee-validate'
import { createApp } from 'vue'
import App from '../App.vue'

createApp(App)
.component('vee-validate', VeeValidate)

// Vue.use(VeeValidate, {
//   //classes: true,
//   validity: true,  // HTML Constrained Validation API;
//                   // see https://baianat.github.io/vee-validate/guide/classes-and-styling.html#styling-with-the-html5-validation-api
//  fieldsBagName: 'veeFields'  // stops invalid error messages realted to the field named "field", like for b-table
//                             // see https://github.com/bootstrap-vue/bootstrap-vue/issues/1270
// })
