<template>
  <b-container fluid>
    <b-row align-v="center">
      <b-col cols="12" sm="12" md="12" lg="12" xl="12" ><site-intro/></b-col>
      <b-col cols="12" sm="12" md="12" lg="12" xl="12" ><info-row/></b-col>
    </b-row>
  </b-container>
</template>

<script>
import SiteIntro from '@/components/home/SiteIntro.vue'
import InfoRow from '@/components/home/InfoRow.vue'

export default {

  name: 'AppHome',

  components: {
    SiteIntro,
    InfoRow,
  },

  data () {
    return {
      interval: 0  // seconds
    }
  }
}
</script>

<style scoped>

.container {
  min-width: 1140px;
}

</style>
