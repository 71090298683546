<template>
  <b-container fluid>
    <hr>
    <b-row class="m-1">
      <b-col cols="2" class="m-2 mt-0" style="max-width: 60px;">
        <AppLink :to="nasa.url" class="logo">
          <BImg :src="nasa.src" :alt="nasa.alt" width="50rem" />
        </AppLink>
      </b-col>

      <b-col sm="2" md="6" lg="12" class="p-1">
        <AppLink to="/missions/agage/sitemap">Site Map</AppLink>
        <font-awesome-icon icon="circle"/>
        <AppLink to="https://www.nasa.gov/about/highlights/HP_Privacy.html">Web Privacy</AppLink>
        <font-awesome-icon icon="circle"/>
        <AppLink to="https://www.nasa.gov/accessibility/">Accessibility</AppLink>

        <br/> 

        <strong>NASA Official: </strong>Gao Chen
        <font-awesome-icon icon="circle"/>
        <strong>Site Curator: </strong>AGAGE Webmaster
        <font-awesome-icon icon="circle"/>
        <strong>Last Updated:</strong> {{ lastUpdated }}
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

import authMixins from '@/components/app/authMixins';
import AppLink from '@/components/app/AppLink.vue'

export default {

  name: 'AppFooter',

  components: {
    AppLink
  },
  
  mixins: [ authMixins ],

  created () {
    this.lastUpdated = process.env.VUE_APP_LAST_UPDATED
  }

}

</script>

<style scoped>


a {
  color: #2F5779;
}

.fa-circle {
  font-size: 30%;
  margin: 10px;
  margin-bottom: 4px;
}

</style>
