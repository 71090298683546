// API w/ async queuing and automatic caching

import axios from 'axios'
import PQueue from 'p-queue'

axios.defaults.withCredentials = true

// https://github.com/sindresorhus/p-queue
const options = {
  concurrency: 4,   // default: 1
  // autoStart: true,  // default: true
  intervalCap: 4,   // default: Infinity; max number of runs in the given interval of time.
  interval: 100,    // default: 0; length of time in ms before the interval count resets.
  // carryoverConcurrencyCount: false // default: false
}

const queue = new PQueue(options)

/*
 * Example:

 api.get('/url')
   .then(response => { do something on success })
   .catch(error => { console.error(error) })
*/

// TODO: TRY AND PUT async before these methods https://www.telerik.com/blogs/how-to-use-axios-vue

export const api = {

  // add an array of functions to the queue;
  // return promise that is satisfied when the queue is both
  // empty and the last function has completed
  addAll (fns) {
    queue.addAll(fns)
    return queue.onIdle()
  },

  // queue a cached axios GET promise and return a promise immediately
  get (url,options) {
    return queue.add( () => {
      return axios.get(url,options || {})
    })
  },

  // posts are neither queued nor cached
  post (url,options) {
    return axios.post(url,options || {})
  },

  // File posts are neither queued nor cached
  postFile (url,formData) {
    return axios({
      method: 'post',
      url: process.env.VUE_APP_API_URL + url,
      data: formData,
      headers: {
        'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
      },
      withCredentials: true
    } || {})
  }

}
