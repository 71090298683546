import { api } from './api'
import { eventBus } from './eventBus'
import axios from 'axios'

const endpointMapper = {
  uploadDocument: "/uploadDocument",
  uploadData: "/data/upload",
  instrumentGroups: "/instruments/groups",
  calendar_instrumentGroups: "/instruments/groups",
  productTypes: "/data/product_types",
  calendar_productTypes: "/data/product_types",
  processingTypes: "/data/processing_types",
  calendar_processingTypes: "/data/processing_types",
  fileTypes: "/data/file_types",
  graphData: "/data/json",
  dataCalendar: "/data/calendar",
  uploadMetrics: "/metrics/upload",
  downloadMetrics: "/metrics/download"
}

export class Endpoint {

  constructor( name, path, query, spinner ) {
    let args = this.parse_args(name, path, query, spinner)

    this.name = args.name
    this.url = args.url
    this.options = args.options
    this.spinner = args.spinner
  }

  parse_args(name, path, query, spinner) {

    let args = typeof name == 'object' ? name :
                {
                  name: name,
                  path: path,
                  query: query,
                  spinner: spinner
                }

    args = {
      name: args.name || "",
      path: args.path || "",
      query: args.query || {},
      spinner: args.spinner === false ? false : true
    }

    if ( args.path && ! args.path.startsWith('/') ) args.path = `/${args.path}`
    if ( ! ('limit' in args.query) ) args.query.limit = 100

    return {
      name: args.name,
      url: process.env.VUE_APP_API_URL + `${args.name in endpointMapper ? endpointMapper[args.name] : ""}${args.path}`,
      options: { params: args.query }, // https://github.com/axios/axios#request-config
      spinner: args.spinner
    }
  }

}


// A convenient way to coordinate getting data and controlling loading spinners
// across different components by communicating through a singleton event bus.
//
// In distributed SOAs (Service Oriented Architectures), event messages should
// be light-weight (i.e., terse messages, not heavy-weight data), in part, because
// serializing and de-serializing data for transfer over the wire is relatively
// expensive and should be kept off the bus (e.g., messages may be logged).
// Here, though, everything is within the same process space; Javascript passes
// primitives by value and objects and arrays by reference on the stack, so the
// internal message payload is very light-weight with no data transformations:
// it is just a reference (a pointer) to the data in RAM; there is no actual
// movement (copying) of data.
eventBus.on('get-data', function (endpoint) {

    // pass an Endpoint or plain JS object to instaniate an Endpoint
    if ( ! (endpoint instanceof Endpoint) ) endpoint = new Endpoint(endpoint)

    // default, delayed loading spinner
    var spinner = endpoint.spinner
    setTimeout( () => spinner && eventBus.emit(`${endpoint.name}:isLoading`,true), 0)

    // use the endpoint name to differentiate in the centralized bus
    api
      .get(endpoint.url, endpoint.options)
      .then( response => { eventBus.emit(`${endpoint.name}:done`, response.data) } )
      .catch( err => {  eventBus.emit(`${endpoint.name}:error`,err.response.data) } )
      .finally( () => spinner && eventBus.emit(`${endpoint.name}:isLoading`,spinner = false) )

});


eventBus.on('put-file', function (endpoint) {

    // pass an Endpoint or plain JS object to instaniate an Endpoint
    if ( ! (endpoint instanceof Endpoint) ) endpoint = new Endpoint(endpoint)

    // default, delayed loading spinner
    var spinner = endpoint.spinner
    setTimeout( () => spinner && eventBus.emit(`${endpoint.name}:isSubmitting`,true), 0)

    // use the endpoint name to differentiate in the centralized bus
    axios({
      method: 'post',
      url: endpoint.url,
      data: endpoint.options.params,
      // headers: {
      //   'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
      // },
      withCredentials: true})
      .then( response => {eventBus.emit(`${endpoint.name}:done`, response); } )
      .catch( err => { eventBus.emit(`${endpoint.name}:error`,err.response.data); console.error(err.response.data) } )
      .finally( () => spinner && eventBus.emit(`${endpoint.name}:isSubmitting`,spinner = false) )

})
