<script setup>

// heavily based on https://router.vuejs.org/guide/advanced/extending-router-link
import { computed } from 'vue'
import { RouterLink } from 'vue-router'

defineOptions({
  inheritAttrs: false,
})

const props = defineProps({
  ...RouterLink.props,
  inactiveClass: String,
})

const isExternalLink = computed(() => {
  let isExternal = false;

  if (typeof props.to === 'string') {
    if (props.to.startsWith('http') ) {
      isExternal = true;
    }
  }
  return isExternal;
})

const isNASALink = computed(() => {
  let isNASALink = false;
  // purposfully only matching exactly nasa.gov because we want the 
  // NASA logo in the footer to be the only nasa link without the 
  // 'this link will leave the site' logo (external-link)
  if (typeof props.to === 'string') {
    if (props.to == 'https://nasa.gov'  ) {
      isNASALink = true;
    }
  }
  return isNASALink;
})

const isMailLink = computed(() => {
  let isMailLink = false;

  if (typeof props.to === 'string') {
    if (props.to.startsWith('mailto:') ) {
      isMailLink = true;
    }
  }
  return isMailLink;
})
</script>

<template>
  <a v-if="isMailLink" v-bind="$attrs" :href="to">
    <slot />
    <font-awesome-icon icon="envelope"/>
  </a>
  <a v-else-if="isExternalLink && !isNASALink" v-bind="$attrs" :href="to">
    <slot /> 
    <font-awesome-icon icon="external-link"/>
  </a>
  <a v-else-if="isNASALink" v-bind="$attrs" :href="to">
    <slot />
  </a>
  <router-link
    v-else
    v-bind="$props"
    custom
    v-slot="{ isActive, href, navigate }"
  >
    <a
      v-bind="$attrs"
      :href="href"
      @click="navigate"
      :class="isActive ? activeClass : inactiveClass"
    >
      <slot />
    </a>
  </router-link>
</template>

<style scoped>

a {
  color: #2F5779;
}

.fa-arrow-up-right-from-square, 
.fa-envelope {
  margin-left: 4px;
  width:12px;
}

</style>
