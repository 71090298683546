export const consts = {

  LOGO: {
    src: require('@/assets/img/logos/AGAGE.png'),
    alt: "Advanced Global Atmospheric Gases Experiment (AGAGE)"
  },

  SMALLLOGO: {
    src: require('@/assets/img/logos/AGAGE_small.png'),
    alt: "Advanced Global Atmospheric Gases Experiment"
  },

  NASALOGO: {
    src: require('@/assets/img/logos/nasa.png'),
    alt: "NASA",
    url: "https://nasa.gov"
  },

  URLS: {
    ident: process.env.VUE_APP_API_URL + '/auth/ident',
    login: process.env.VUE_APP_API_URL + '/auth/login',
    logout: process.env.VUE_APP_API_URL + '/auth/logout',
    documentDownload: process.env.VUE_APP_API_URL + '/document/download',
    dataDownload: process.env.VUE_APP_API_URL + '/data/download',

    contactUs: '/contact-us',
    support: 'mailto:support-asdc@earthdata.nasa.gov',
    dataIDs: 'https://www-air.larc.nasa.gov/missions/etc/IcarttDataFormat.htm#4'
  },

  IMAGES: {
    ABOUT: {
      src: require('@/assets/img/about/about-lg.jpg'),
      alt: "Image of three different stations."
    },
    TEAM: {
      src: require('@/assets/img/team/global_banner.jpg'),
      alt: "Image of three different stations."
    },
  },
}
