import { reactive } from 'vue'
import { eventBus } from '@/init/eventBus'

/*
 * Explicitly not using Vuex; just simple repository (store) pattern
 * that uses browser sessionStorage/localStorage for persistence across
 * page refreshes;
 *
 * See also https://vuejs.org/v2/guide/state-management.html
 *
 */

export const store = reactive({

  install(app, options) {
    // configure the app
  },

  name: 'Store',

  data () {
    return {

      user: {
        uid: '',
        email: '',
        //: '',
        isLoggedIn: false,
        isAdmin: false,
        name: {
          first: '',
          last: ''
        },
      }
    }
  },

  watch: {

    // with the exception of 'users,' watchers are not "deep":
    // writing to the store is to be atomic, triggered only by assignment
    // of the root object to a new object

    user: {
      handler: 'onUserWatch',
      deep: true,
      //immediate: true // if true, page refresh will set init values before
                        // call to restore(), erroneously resetting the user.
    }
  },

  methods: {

    onUserWatch () {
      this._set('user',this.user)
    },

    clear () {
      sessionStorage.clear()  // erases everything, incl. keys not tracked (if any);
      this.reset()            // reactive; syncs internal store w/ cleared sessionStorage
    },

    get (key) {
      const val = sessionStorage.getItem(key)
      if ( val == null ) throw `store.get(): unknown key: ${key}`
      return JSON.parse(val)
    },

    has (key) {
      return sessionStorage.getItem(key) != null
    },

    // in normal usage, do not call _set() directly,
    // just assign a value and let the watcher do the set;
    // e.g.: store.myKey = myValue
    _set (key,val) {
      sessionStorage.setItem(key,JSON.stringify(val))
      eventBus.emit(`store:set-${key}`, key)
    },

    // merge objects, otherwise type-check set
    put (key,val) {

      const storedValue = this.get(key)

      if ( typeof storedValue != typeof val )
        throw `store.put(): type mismatch for key: '${key}'; ` +
              `stored value is typeof '${typeof storedValue}', ` +
              `but put value is typeof '${typeof val}'`

      // merge (for objects only);
      // reactive: store.get(key) will not be available until $nextTick()
      this[key] = (typeof val == 'object') ? { ...storedValue, ...val } : val

    },

    // key is optional: reset() resets all; see also clear()
    reset (key) {
      if ( arguments.length == 0 )
        Object.keys(this.defaults).forEach(key => this.reset(key))
      else {
        if ( ! (key in this.defaults) ) throw `store.reset(): unknown key: ${key}`
        this[key] = _.cloneDeep(this.defaults[key])  // reactive
      }
    },

    // to reconstitute from page refresh, use restore() instead of reset();
    // key is optional: restore() restores all or sets defaults
    restore (key) {
      if ( arguments.length == 0 )
        Object.keys(this.defaults).forEach(key => this.restore(key))
      else try { this[key] = this.get(key) } // reactive
           catch (e) { this.reset(key) }     // on key does not exist, set default
    }

  },

  created () {

    this.defaults = {

      user: {
        uid: '',
        email: '',
        expiresOn: '',
        isLoggedIn: false,
        isAdmin: false,
        name: {
          first: '',
          last: ''
        },
      }
    }

    // stash a (shallow) copy of value in this store's "grab bag;" do not save tp
    // sessionStorage; will be lost/destroyed whenever this Vue object is destroyed,
    // e.g., on page refresh. Copies, but does not serialize value; non-reactive
    this.bag = { default: {} }

    // optional namespace for key:value store (default namespace == 'default' )
    this.stash = function (namespace,key,value) {

      let _stash = (key,value,namespace = 'default') => {

        if ( ! (namespace in this.bag) ) this.bag[namespace] = { }

        this.bag[namespace][key] = Array.isArray(value)
                            ? [ ...value ]
                            : ( typeof value == 'object' ? { ...value } : value )
      }

      if ( arguments.length == 2 ) _stash(arguments[0],arguments[1])
      else _stash(key,value,namespace)

    }

    this.grab = function (namespace,key,defaultValue) {

      let _grab = (key,namespace = 'default') => {

        try {
          if ( ! (namespace in this.bag) ) throw `store.grab(): unknown namespace: ${namespace}`
          if ( ! (key in this.bag[namespace]) ) throw `store.grab(): namespace: ${namespace}; unknown key: ${key}`
        } catch (e) {
          if ( defaultValue != undefined ) this.stash(namespace,key,defaultValue)
          else throw e
        }

        return this.bag[namespace][key]
      }

      return arguments.length == 1 ? _grab(arguments[0]) : _grab(key,namespace)

    }

    this.peek = function (namespace,key) {

      let _peek = (key,namespace = 'default') => {
        return (namespace in this.bag) && (key in this.bag[namespace])
      }

      return arguments.length == 1 ? _peek(arguments[0]) : _peek(key,namespace)

    }

    // init from sessionStorage, or if missing, from defaults
    this.restore()

  }

})
